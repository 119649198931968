clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

media-gt($resolution) {
  @media only screen and (min-width: $resolution) {
    /* stylelint-disable */
    {block}
    /* stylelint-enable */
  }
}

media-lt($resolution) {
  @media only screen and (max-width: $resolution) {
    /* stylelint-disable */
    {block}
    /* stylelint-enable */
  }
}

btn() {
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: all 0.25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}
