@critical {
  // Base

  $menu {
    ul,
    ol,
    li {
      margin: 0;
      list-style: none;
      user-select: none;
    }

    a {
      text-decoration: none;
    }

    .active > a,
    .is-active > a {
      cursor: default;
    }

    li,
    a {
      user-select: none;
    }

    a:not([href]) {
      cursor: default;
    }
  }

  $inline-menu {
    li {
      display: inline-block;
    }
  }

  $horizontal-menu {
    > ul {
      display: flex;
    }
  }

  $vertical-menu {
    li {
      display: block;
    }
  }

  $justify-menu {
    > ul,
    > ol {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  $table-menu {
    ul {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    li {
      display: table-cell;
    }
  }
}


// Breadcrumbs

@critical {
  .breadcrumbs {
    padding-top: 1em;
    padding-bottom: 0.5em;
    font-size: 1.3rem;
    font-weight: bold;

    @media $handheld {
      padding-bottom: 1.5em;
    }

    @media $small-mobile {
      height: 0;
      padding: 0;
      overflow: hidden;
      margin-top: 20px;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      position: relative;
    }

    li + li {
      margin-left: 1.5em;
    }

    span {
      color: $black;
    }

    a {
      text-decoration: none;
      color: #8a9895;

      &:hover {
        color: $black;
      }
    }
  }

  .mobile-back-link {
    btn();
    width: 30px; height: 30px;
    font-size: 0;
    position: relative;
    margin-left: -10px;
    margin-bottom: 5px;

    &:before {
      content: '';
      display: block;
      width: 11px; height: @width;
      border-top: 2px solid #c4c4c4;
      border-left: @border-top;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-30%, -50%) rotate(-45deg);
    }
  }
}


// Pager

@critical {
  .pager {
    border-top: 1px solid #c4c4c4;
    border-bottom: @border-top;
    font-size: 1.5rem;
    height: 53px;
    line-height: @height - 3px;

    @media $handheld {
      height: 31px;
      line-height: @height - 3px;
    }

    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }

    li + li {
      margin-left: 0.35ch;
    }

    a {
      display: block;
      text-decoration: none;
      color: $gray;
      padding: 0 0.5em;
      font-size: 1.3rem;
    }

    li.active a {
      color: $black;
      font-weight: bold;
    }

    li:not(.active) a {
      &:hover {
        color: $black;
      }
    }

    .previous {
      margin-right: 15px;
    }

    .next {
      margin-left: 10px;
    }

    .previous a,
    .next a {
      width: 20px; height: 20px;
      position: relative;
      box-sizing: border-box;
    }

    .previous a,
    .next a {
      &:before {
        content: '';
        display: block;
        width: 11px; height: @width;
        border-top: 2px solid #c4c4c4;
        position: absolute;
        left: 50%; top: 50%;
        transition: border-color 0.25s ease;
      }

      &:hover:before {
        border-color: $black;
      }
    }

    .previous a:before {
      border-left: 2px solid #c4c4c4;
      transform: translate(-30%, -50%) rotate(-45deg);
    }

    .next a:before {
      border-right: 2px solid #c4c4c4;
      transform: translate(-70%, -50%) rotate(45deg);
    }
  }

  .pager--bottom {
    display: inline-block;
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    margin-left: -50px;

    @media only screen and (min-width: 2201px) {
      margin-left: 0;
    }

    @media $desktop {
      @media $small-notebook-and-less {
        margin-top: -5px;
      }
    }

    @media $small-mobile {
      margin-top: 4em;
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}


// popup menu

@critical {
  .catalogue-menu {
    position: fixed;
    left: 0; top: 0;
    z-index: 2000;
    transform: translateX(-100%);
    transition: all 0.25s ease;
  }
}

.catalogue-menu {
  background: $white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  &:after {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    pointer-events: none;
    transition: box-shadow 0.25s ease;
  }

  @media $small-mobile {
    width: 100%;
    box-sizing: border-box;
  }
}

.menu-is-opened {
  .catalogue-menu {
    transform: translateX(0);

    &:after {
      box-shadow: 0 2px 13px rgba(0, 30, 98, 0.16);
    }
  }

  // .site-container {
  //   &:before {
  //     content: '';
  //     display: block;
  //     width: 100%; height: 100%;
  //     position: fixed;
  //     left: 0; top: 0;
  //     z-index: 1999;
  //     background: rgba($black, 0.3);
  //   }
  // }
}

.catalogue-menu__content {
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;

  @media $small-handheld {
    padding-top: 25px;
    padding-right: 40px;
  }

  @media $small-handheld {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media $small-mobile {
    padding-left: $mobile-paddings;
    padding-right: $mobile-paddings;
    padding-top: 25px;
    padding-bottom: 70px;
  }
}

.catalogue-menu__caption {
  padding-top: 36px;
  padding-bottom: 30px;
  border-bottom: 1px solid $light-blue;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 1em;
  margin-left: 82px;

  @media $small-handheld {
    display: none;
  }
}

.catalogue-menu__close-button {
  btn();
  width: 40px; height: @width;
  position: absolute;
  right: 25px; top: 30px;
  z-index: 200;
  transform: rotate(-45deg);

  @media $small-mobile {
    right: 5px; top: 5px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    background: $black;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 12px; height: 3px;
  }

  &:after {
    width: 3px; height: 12px;
  }
}

@media $small-handheld {
  .catalogue-menu__group {
    @extend $menu;
    border-top: 1px solid $light-blue;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.catalogue-menu__group ul {
  font-size: 1.4rem;
  font-weight: bold;

  @media $small-handheld {
    font-size: 1.3rem;
    overflow: hidden;
    transition: all 0.25s ease;
  }

  li {
    list-style: none;
    margin-bottom: 0.35em;
  }

  a {
    display: block;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    padding-right: 55px;
    padding-left: 82px;
    text-decoration: none;
    border-right: 3px solid transparent;

    @media $small-handheld {
      padding-left: 0;
      padding-right: 25px;
    }
  }

  a:hover {
    color: $gray;
  }
}

@media $not-small-handheld {
  .catalogue-menu__list--service,
  .catalogue-menu__contact {
    display: none;
  }
}

.catalogue-menu__contact {
  border-top: 1px solid $light-blue;
  padding-top: 20px;
  font-size: 1.2rem;
  line-height: 2;
}

.catalogue-menu__worktime,
.catalogue-menu__email {
  margin-top: 0.5em;
}

.catalogue-menu__email {
  font-weight: bold;
}
