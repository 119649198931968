.tabs {
  .tabs-panel {
    display: none;
  }

  .tabs-panel.is-active {
    display: block;
  }
}

.tabs--default {
  .tabs-nav {
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    font-weight: $regular;
    color: $white;

    @media $small-notebook-and-less {
      font-size: 1.8rem;
    }

    li {
      list-style: none;
    }

    a {
      btn();
      padding: 0.5em 1.5em;
      position: relative;
      border-bottom: 3px solid $gray;
      color: $white;
      -webkit-text-stroke: 0.5px transparent;

      &:hover {
        color: $white !important;
      }
    }

    .is-active a {
      border-bottom-color: $turq;
      -webkit-text-stroke: 0.5px $white;
    }
  }

  .tabs-content {
    margin-top: 65px;
  }
}
