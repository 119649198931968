// Base

@critical {
  input,
  textarea {
    font: inherit;
  }
}

@critical {
  .form__input,
  .form__textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 0.65em 0;
    line-height: 1.25em;
    color: lighten($text-color, 5);
    border: none;
    border-bottom: 1px solid #c4c4c4;
    transition: all 0.25s ease;
    font-size: inherit;

    @media $small-mobile {
      font-size: 1.5rem;
      padding: 1em 0;
      text-align: center;
    }

    &:focus {
      color: $text-color;
      border-color: $black;
    }

    .form-field.error > & {
      border-color: $warning-color;
      color: $warning-color;
    }
  }

  .form__textarea,
  textarea.form__input {
    border: 1px solid #000;
    padding-left: 0.65em;
    padding-right: 0.65em;
    min-height: 2em;
  }

  .checkbox {
    padding-left: 40px;
    position: relative;
    padding-top: 0.25em;

    @media $small-mobile {
      padding-top: 0.5em;
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:before {
      content: '';
      display: block;
      width: 26px; height: @width;
      box-sizing: border-box;
      position: absolute;
      left: 0; top: 0;
      border: 1px solid $gray;
      background: $white;
      border-radius: 50%;
    }

    &:after {
      content: '';
      display: block;
      width: 16px; height: @width;
      border-radius: 50%;
      background: $gray;
      position: absolute;
      left: 5px; top: @left;
      opacity: 0;
    }

    &.is-checked:after {
      opacity: 1;
    }

    input {
      display: none;
    }
  }

  .radio {
    padding-left: 60px;
    position: relative;
    padding-top: calc((29px - 1em) / 2);
    cursor: pointer !important;

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 29px; height: @width;
      border-radius: 50%;
      border: 1px solid #797979;
      position: absolute;
      left: 0; top: 0;
      transition: all 0.25s ease;
    }

    &.is-checked:before {
      background: $yellow;
      border-color: $yellow;
    }

    &.is-checked ~ .text-hint.hint-anchor {
      display: block;
    }
  }

  .text-hint.hint-anchor {
    color: $red;
    margin-top: 10px;
    display: none;

    @media $small-mobile {
      font-size: 12px;
    }
  }

  .spinner {
    display: inline-block;
    vertical-align: middle;
    padding: 0 40px;
    position: relative;

    @media $small-mobile {
      padding: 0 20px;
    }
  }

  .spinner__input {
    box-sizing: border-box;
    vertical-align: middle;
    border: none;
    width: 68px;
    padding: 0 5px;
    text-align: center;
    font-size: 3.2rem;
    font-weight: bold;
    border: none;
    line-height: 40px;

    @media $notebook-and-less {
      font-size: 2.4rem;
      width: 50px;
    }

    @media $small-mobile {
      font-size: 1.7rem;
    }
  }

  .spinner__control {
    btn();
    box-sizing: border-box;
    vertical-align: middle;
    width: 20px; height: 100%;
    text-align: center;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';
      display: block;
      width: 17px; height: @width;
      border-top: 1px solid $black;
      position: absolute;
      left: 50%; top: 50%;

      @media $small-mobile {
        width: 14px; height: @width;
      }
    }
  }

  .spinner__control--down {
    left: 0;

    &:before {
      border-left: 1px solid $black;
      transform: translate(-20%, -50%) rotate(-45deg);
    }
  }

  .spinner__control--up {
    right: 0;

    &:before {
      border-right: 1px solid $black;
      transform: translate(-80%, -50%) rotate(45deg);
    }
  }
}


@critical {
  .form__fieldset + .form__fieldset {
    margin-top: 4em;
  }

  .form__fieldset-caption {
    font-weight: bold;
    width: 100%;
    float: left;
    margin-bottom: 1.5em;

    @media $small-mobile {
      text-align: center;
    }
  }

  .form__row {
    clear: left;

    .form--two-columns & {
      display: flex;
      align-items: center;
    }
  }

  @media $not-small-mobile {
    .form__row--nolabel {
      padding-left: 140px;
    }
  }

  .form__row + .form__row {
    .form--one-column & {
      margin-top: 1em;
    }

    .form--two-columns & {
      margin-top: 0.5em;
    }
  }

  .form__row--radio {
    .form--two-columns & {
      padding-top: 1em;
      align-items: flex-start;
    }

    .form__label {
      margin-top: calc((29px - 1em) / 2);
    }
  }

  .form__field--radio {
    padding-bottom: 1em;
  }

  .form__label {
    .form--one-column & {
      margin-bottom: 0.25em;
    }

    .form--one-column .form__row--radio & {
      margin-bottom: 1em;
    }

    .form--two-columns & {
      box-sizing: border-box;
      flex-basis: 200px;
      padding-right: 1.5em;

      @media $small-mobile {
        display: none;
      }
    }
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1.1;
    color: $warning-color;

    @media $not-small-mobile {
      margin-top: 10px;
    }
  }

  .form__field {
    position: relative;

    .form--two-columns & {
      flex: 1 auto;
    }

    &.error {
      .errorMessage {
        display: block;
      }
    }
  }

  .form__hint {
    font-size: 1.2rem;
    line-height: 1.5;
    color: $black;

    .form--one-column & {
      margin-top: 2em;
    }

    .form--two-columns & {
      margin-top: 2.5em;
    }
  }

  .form__submit {
    text-align: center;

    .form--one-column & {
      margin-top: 2em;
    }

    .form--two-columns & {
      margin-top: 3em;
    }
  }

  .form__submit-button {
    btn();
    padding: 0.35em 0.5em;
    font-family: $caption-font;
    font-size: 2.4rem;
    font-weight: normal;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%; height: 1px;
      background: $yellow;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      transition: all 0.25s ease;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:hover {
      &:before,
      &:after {
        max-width: 60%;
      }
    }
  }

  .radio-input + .radio-input {
    margin-top: 1.5em;

    .form--two-columns & {
      margin-top: 0.5em;
    }
  }

  .form__input-hint {
    font-size: 1.2rem;
    color: $gray;
  }
}

.popup-form {
  width: 448px;
  max-width: 100%;
  font-size: 1.5rem;
  font-weight: bold;

  .form__row {
    border-bottom: 1px solid #c4c4c4;
  }

  .form__input {
    font-size: inherit;
    border-bottom: none;
    padding-top: 1em;
    padding-bottom: 1em;

    @media $small-mobile {
      text-align: left;
    }
  }

  .form__label {
    display: block;
  }

  .form__row--radio {
    display: block;

    .form__label {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}

.feedback-form {
  font-size: 1.8rem;
}

.promocode-form {
  margin-bottom: 50px;
  position: relative;

  .inp {
    text-align: center;
    font-size: 32px;
    color: $black;

    @media $small-handheld {
      font-size: 20px;
    }
  }

  .form-field {
    text-align: center;
  }

  .promo-code-input.success {
    border-color: $turq;
  }

  .promo-code-input.success ~ .promo-code-hint {
    color: $turq;
  }

  .promo-code-input.error {
    border-color: $purple;
    color: $purple !important;
  }

  .promo-code-input.error ~ .promo-code-hint {
    color: $purple !important;
  }

  .promo-code-hint {
    text-align: center;
    margin-top: 5px;
  }

  .promocode-link {
    position: absolute;
    left: auto;
    right: 0;
    top: 30px;
    display: block;
    width: 26px; height: 26px;
    box-sizing: border-box;
    font-size: 0;
    border-radius: 50%;

    @media $small-handheld {
      top: 15px;
    }

    @media $small-mobile {
      top: 20px;
    }

    &:not(.clear-btn) {
      background-color: $yellow;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 45%;
        translate: -50% -50%;
        rotate: 45deg;
        width: 6px; height: @width;
        background-color: transparent;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
      }
    }

    &.clear-btn {
      background-color: #d9d9d9;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%; left: 50%;
        width: 10px; height: 2px;
        background-color: $white;
        rotate: 45deg;
        translate: -50% -50%;
      }

      &::after {
        display: block;
        content: '';
        position: absolute;
        width: 10px; height: 2px;
        background-color: $white;
        rotate: -45deg;
        translate: -50% -50%;
        top: 50%; left: 50%;
      }
    }
  }
}

.textarea-symbol-counter {
  font-weight: 400;
  font-size: 13px;
  text-align: right;
}

.inp-placeholder {
  margin-bottom: 5px;
}

.fast-order-popup__form {
  width: 370px;
  position: absolute;
  top: 115px;
  right: 40px;

  @media $mobile {
    position: unset;
    width: unset;
  }

  .form__hint {
    font-size: 1rem;
  }
}

.fast-order-form {
  .form__input {
    background-color: transparent;
    padding: 6px 0 6px 15px;
    color: $white;
  }

  .form__hint {
    margin-top: 2.5em;
    color: $white;

    a {
      color: $turq;
    }
  }

  .form__submit {
    margin-top: 3em;
  }

  .form__submit-button {
    background: $yellow;
    width: 100%;
    line-height: 80px;
    padding: 0;
    font-size: 3rem;

    @media $small-mobile {
      line-height: 40px;
      font-size: 1.8rem;
    }

    &:hover {
      background: $black;
      color: $white;
    }
  }
}
