@critical {
  html {
    font: $root-font-size / $line-height $master-font;

    @media $mobile {
      font-size: $root-mobile-font-size;
    }
  }

  body {
    background: $body-bg;
    color: $text-color;
    font-size: $font-size;
  }
}

::-moz-selection {
  background: $black;
  color: $white;
}

::selection {
  background: $black;
  color: $white;
}


// Headings

@critical {
  .h1,
  .h2,
  .h3,
  .h4 {
    font-family: $caption-font;
    font-weight: normal;
    line-height: 1.2;
  }

  .h1 {
    @media $not-notebook {
      font-size: 5.6rem;
    }

    @media $notebook {
      font-size: 4.5rem;
    }

    @media $tablet {
      font-size: 2.8rem;
    }

    @media $small-mobile {
      font-size: 2.4rem;
    }
  }

  .h2 {
    @media $not-notebook {
      font-size: 4rem;
    }

    @media $notebook {
      font-size: 3.5rem;
    }

    @media $tablet {
      font-size: 2.5rem;
    }

    @media $small-mobile {
      font-size: 2.4rem;
    }
  }

  .h3 {
    @media $not-notebook {
      font-size: 3.4rem;
    }

    @media $notebook {
      font-size: 2.6rem;
    }

    @media $tablet {
      font-size: 2.2rem;
    }

    @media $small-mobile {
      font-size: 1.8rem;
    }
  }

  .h4 {
    font-size: 1rem;
  }

  .caption {
    @extend .h1;
    font-weight: $bold;
    margin-bottom: 1em;
  }

  .subcaption {
    @extend .h2;
    font-weight: $bold;
    margin-bottom: 1em;
  }

  .small-caption {
    @extend .h3;
    margin-bottom: 1em;
  }
}


// Sub, sup

sub,
sup {
  position: relative;
  font-size: 0.6em;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


// Links

@critical {
  a {
    color: $link-color;

    &:hover {
      color: $hovered-link-color;
    }
  }
}

a.hova,
.hova a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.unhova,
.unhova a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.nova,
.nova a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@critical {
  a[href^="tel:"] {
    text-decoration: none;
  }
}


// Sizes

for $i in (10..40) {
  .s{$i} {
    font-size: remove-unit(1 / $root-font-size) * $i * 1rem;
    line-height: $line-height;
  }
}


// Font weight

.thin,
.tt {
  font-weight: $thin;
}

.light,
.ll {
  font-weight: $light;
}

.normal,
.nn {
  font-weight: normal;
}

.bold,
.bb {
  font-weight: bold;
}

.medium,
.mm {
  font-weight: $medium;
}


// Font style

.style-normal {
  font-style: normal;
}

.italic,
.ii {
  font-style: italic;
}


// Text case

.uppercase,
.upcase {
  text-transform: uppercase;
}

.nocase {
  text-transform: none !important;
}


// Text decoration

.underline {
  text-decoration: underline;
}


// Colors

.black,
a.black {
  color: $black;
}

.white,
a.white {
  color: $white;
}


// Text align

@critical {
  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
}


// Word wrap

.nowrap {
  white-space: nowrap;
}


// Text container

@critical {
  .text-container {
    clearfix();

    * + p,
    * + table,
    * + ul,
    * + ol,
    * + div {
      margin-top: 1.25em;
    }

    > * + figure,
    > * + .images-group {
      margin-top: 3em;
    }

    * + section {
      padding-top: 4.5em;
    }

    * + blockquote {
      margin-top: 2em;
    }

    h1,
    h2,
    h3,
    h4,
    .h1,
    .h2,
    .h3,
    .h4 {
      margin-bottom: 0.75em;

      & + * {
        margin-top: 0;
      }
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + .h1,
    * + .h2,
    * + .h3 {
      margin-top: 3em;
    }

    * + .h4 {
      margin-top: 1.5em;
    }

    hr + h3,
    hr + .h3 {
      margin-top: 1em;
    }

    ol {
      margin-left: 0;
      list-style: none;
      counter-reset: custom-counter;

      li {
        position: relative;
      }

      li:before {
        content: counter(custom-counter);
        counter-increment: custom-counter;
        position: absolute;
        left: 0; top: 0;
        color: #797979;
      }
    }

    .float-list {
      counter-reset: parent-custom-counter;
      margin-left: 0;

      for $i in 1..20 {
        &[start=\"{$i}\"] {
          counter-reset: parent-custom-counter $i - 1;
        }
      }

      ul {
        margin-top: 0 !important;
      }

      > li,
      ol > li {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      > li:before {
        content: counter(parent-custom-counter);
        counter-increment: parent-custom-counter;
      }

      ol {
        counter-reset: custom-counter;
        margin-left: 0;

        > li {
          padding-left: 90px;
          position: relative;

          @media $small-mobile {
            padding-left: 28px;
          }
        }

        > li:before {
          counter-increment: custom-counter;
          content: counter(parent-custom-counter) '.' counter(custom-counter);
          color: #797979;
          position: absolute;
          left: 0; top: 0;
        }
      }

      .propagate-counter {
        &:before {
          visibility: hidden;
          pointer-events: none;
          position: absolute;
        }

        [data-propagate-counter] {
          &:before {
            content: counter(parent-custom-counter) '.';
          }
        }
      }
    }

    ul > li {
      list-style: none;

      &:before {
        content: '●';
        font-size: 0.75em;
        display: inline-block;
        position: absolute;
        left: 0; top: 0.2em;

        @media $small-mobile {
          font-size: 1.5em;
          top: calc(1.2em - 1.5em);
        }
      }
    }

    li {
      padding-left: 2em;
      position: relative;
    }

    li {
      margin: 0.25em 0;

      > ul,
      ol {
        margin-left: 1em;
      }
    }

    blockquote {
      background: lighten($gray, 20);
      font-size: 1.34em;
      padding: 2.5vw 3.5vw;
      margin-top: 3em;
      margin-bottom: 3em;

      @media $small-handheld {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
      }
    }
  }

  .text-spoiler {
    border-top: 1px solid #c4c4c4;
  }

  .text-spoiler__caption {
    font-size: 2.4rem;
    font-weight: bold;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-right: 75px;
    position: relative;
    cursor: pointer;

    @media $small-mobile {
      font-size: 1.5rem;
      padding-top: 1em;
      padding-bottom: 1em;
      padding-right: 40px;
    }
  }

  .text-spoiler__toggler {
    btn();
    width: 30px; height: 30px;
    position: absolute;
    right: 20px; top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease;

    @media $small-mobile {
      right: 5px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 16px; height: 2px;
    }

    &:after {
      width: 2px; height: 16px;
    }
  }

  .text-spoiler__body {
    overflow: hidden;
    margin: 0 !important;
    font-size: 1.8rem;
    line-height: 2;
    transition: all 0.25s ease;

    @media $small-mobile {
      font-size: 1.5rem;
      line-height: 1.2;
    }
  }

  .text-spoiler:not(.is-expanded) {
    .text-spoiler__body {
      max-height: 0;
      opacity: 0;
    }
  }

  .text-spoiler.is-expanded {
    .text-spoiler__body {
      max-height: var(--max-height);
      padding-top: 1em;
      padding-bottom: 3em;
    }

    .text-spoiler__toggler {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .images-group {
    @media $not-small-mobile {
      display: flex;

      > * {
        flex: 1 1 0;
      }

      > *:not(:last-child) {
        margin-right: 0 !important;
      }

      > * + * {
        margin-left: 40px !important;
      }
    }

    @media $small-mobile {
      margin-left: - $mobile-paddings;
      margin-right: - $mobile-paddings;

      > * + * {
        margin-top: 20px !important;
      }
    }
  }

  .images-group__image-container {
    line-height: 0;
  }

  .text-image-group {
    display: flex;

    @media $small-mobile {
      flex-direction: column;

      * + & {
        margin-top: 4.5em !important;
      }
    }

    > * + * {
      @media $not-small-mobile {
        margin-left: 3vw;
        margin-top: 0 !important;
      }
    }
  }

  @media $not-small-mobile {
    .text-image-group__image-container,
    .text-image-group__text {
      flex: 50% 0;
    }
  }

  @media $small-mobile {
    .text-image-group__image-container {
      order: 1;
      margin-top: 0 !important;
    }

    .text-image-group__text {
      order: 2;
      margin-top: 2em !important;
    }
  }

  @media $not-small-mobile {
    .small-text {
      font-size: 0.75em;
    }
  }

  @media $small-mobile {
    .mobile-small-text {
      font-size: 1.3rem;
    }
  }
}

@media $small-mobile {
  .table-container {
    max-width: 100%;
    overflow-x: auto;
    margin-left: - $mobile-paddings;
    margin-right: - $mobile-paddings;
    padding-left: $mobile-paddings;
    padding-right: $mobile-paddings;
  }
}

.image-caption {
  font-size: 1.4rem;
  color: $gray;
  margin-top: 1em;
  margin-bottom: 5em;

  @media $handheld {
    font-size: 1.2rem;
  }

  @media $small-mobile {
    font-size: 1.5rem;
  }
}


// HR

hr {
  height: 1px;
  margin: 2em auto 1.5em;
  background: #c4c4c4;
  max-width: 1268px;

  @media $small-mobile {
    max-width: 271px;
    margin: 2.5em auto;
  }
}

.hr--short {
  max-width: 328px;
  margin-bottom: 2.5em;

  @media $small-mobile {
    max-width: 164px;
    margin-bottom: 3em;
  }
}

.hr--black {
  background: $black;
}

.hr--no-margin {
  margin-top: 0;
  margin-bottom: 0;
}


// Icons

.icon {
  display: inline-block;
}


// Video

.video {
  position: relative;
  padding-bottom: (9 / 21) * 100%; // 21:9 aspect ratio

  > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  &.ar-16by9 {
    padding-bottom: (9 / 16) * 100%; // 16:9 aspect ratio
  }

  &.ar-4by3 {
    padding-bottom: (3 / 4) * 100%; // 4:3 aspect ratio
  }
}
