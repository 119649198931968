// Lift

.go-top-btn.is-visible {
  opacity: 1;
  max-height: 55px;
}

.go-top-btn {
  width: 55px; height: @width;
  border-radius: 50%;
  position: fixed;
  right: 34px; bottom: 105px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  cursor: pointer;
  background: $white;
  box-shadow: 2px 2px 10px rgba(113, 128, 150, 0.3);
  transition: all 0.25s ease;

  @media $small-handheld {
    display: none;
  }

  &:hover {
    box-shadow: 2px 2px 10px rgba(113, 128, 150, 0.5);

    &:before {
      border-color: #22c1ae;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 9px; height: @width;
    border-top: 4px solid rgba(30, 30, 30, 1);
    border-right: 4px solid rgba(30, 30, 30, 1);
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -45%) rotate(-45deg);
    transition: all 0.25s ease;
  }
}
