@critical {
  .btn {
    btn();
  }
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover,
  &:focus,
  &.is-active {
    background: $black;
    color: $white;
  }
}

.black-btn {
  background-color: $black;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
    background-color: lighten($black, 20);
  }
}


// Sizes

.wide-btn {
  width: 100%;
}
