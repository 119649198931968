// Base

@critical {
  .popup {
    display: none;
  }
}

.popup {
  position: absolute;
  background: $body-bg;
  z-index: 9999;
  padding: 45px 62px 40px 86px;
  box-shadow: 0 15px 30px rgba(178, 204, 209, 0.5);
  max-width: 100%;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    width: width('i/popup-decor.svg'); height: height('i/popup-decor.svg');
    background: url(/i/popup-decor.svg) no-repeat 50% / contain;
    position: absolute;
    left: -30px; top: 30px;
    z-index: 1;

    @media $small-mobile {
      width: 34px; height: 50px;
      left: -17px; top: 12px;
    }
  }

  @media $small-mobile {
    padding: 25px 25px 25px 37px;
    max-width: calc(100% - 40px);
  }
}

.popup__close {
  @extend .btn;
  width: 40px; height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 45px; right: 30px;
  transform: rotate(45deg);
  z-index: 10;
  cursor: pointer;

  @media $small-mobile {
    top: 15px; right: 15px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    background: $black;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.25s ease;
  }

  &:before {
    width: 16px; height: 2px;
  }

  &:after {
    width: 2px; height: 16px;
  }

  &:hover {
    &:before,
    &:after {
      background: $yellow;
    }
  }
}

.popup__caption {
  font-family: $caption-font;
  font-size: 3rem;
  padding-right: 30px;
  text-align: center;
  margin-bottom: 1em;

  @media $small-mobile {
    font-size: 1.6rem;
  }
}

.fast-order-popup {
  padding-bottom: 65px !important;

  @media $mobile {
    padding-bottom: 70px !important;
  }

  .quick-view__price-block {
    margin-left: 25px;
  }

  .quick-view__amount {
    max-width: 160px;
  }

  .quick-view__price-line {
    margin-top: 15px;

    @media $small-mobile {
      margin-top: 0;
    }
  }
}

.fast-order__layout {
  margin-bottom: 15px;
}

.fast-order-popup__content-buttons {
  margin-bottom: 15px;
}

.fast-order-popup__caption {
  margin-bottom: 1.75em;
}

.fast-order-popup__product {
  flex-basis: 50%;
  flex-shrink: 1;
}

@media $small-mobile {
  .fast-order-popup__product-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .fast-order-popup__product-description {
    margin-bottom: auto;
  }
}

.fast-order-popup__product-image-container {
  line-height: 0;
  margin-bottom: 10px;

  @media $small-mobile {
    flex-basis: 81px;
    flex-shrink: 0;
    margin-right: 17px;
    margin-bottom: 0;
  }
}

.fast-order-popup__product-description {
  font-size: 1.3rem;
}

.fast-order-popup__product-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;
}

.fast-order-popup__product-price {
  white-space: nowrap;
  font-size: 4rem;
  font-weight: $bold;
  line-height: 46px;
}

.fast-order-popup__product-price--old {
  color: $gray;
  position: relative;
  margin-left: 3px;
  margin-right: 15px;
  white-space: nowrap;
  font-size: 2rem;
  order: 1;

  &:after {
    content: '';
    display: block;
    width: calc(100% + 6px); height: 1px;
    background: #797979;
    position: absolute;
    left: -3px; top: 50%;
    transform: translateY(-50%);
  }
}

.fast-order-popup__product-favorite {
  btn();
  width: 34px; height: 32px;
  background: url(/i/favorite-vitrine-icon.svg) no-repeat 50%;
  filter: invert(1);

  &.is-active {
    background-image: url(/i/favorite-vitrine-icon-active.svg);
  }
}

.fast-order-popup__product-compare {
  btn();
  width: 32px; height: @width;
  background: url(/i/compare-vitrine-icon.svg) no-repeat 50%;
  filter: invert(1);

  &.is-active {
    background-image: url(/i/compare-vitrine-icon-active.svg);
  }
}

.card-calculator {
  padding: 30px 17px;
  width: 330px;
  font-size: 1.5rem;

  &:before {
    width: 42px; height: 62px;
    left: -21px; top: 7px;
  }
}

.card-calculator__caption {
  font-size: 1.6rem;
  text-align: left;
  margin-left: 13px;
  margin-bottom: 2em;
}

.card-calculator__close {
  right: 5px; top: 15px;
}

.card-calculator__sizes {
  display: flex;
  font-size: 1.3rem;
  margin-bottom: 1.25em;
  margin-right: -7px;

  dt {
    margin-right: 5px;
  }

  dd {
    margin-right: 7px;
  }
}

.card-calculator__tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 1em;
}

.card-calculator__tabs-button {
  btn();
  position: relative;
  padding: 0.25em 0.15em;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%; height: 2px;
    background: $yellow;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 0;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.is-active {
    &:before,
    &:after {
      max-width: 100%;
    }
  }
}

.card-calculator__tabs-button + .card-calculator__tabs-button {
  margin-left: 30px;
}

.card-calculator__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 278px;
}

.card-calculator__line + .card-calculator__line {
  margin-top: 0.75em;
}

.card-calculator__input {
  width: 70px;
  border-bottom: 1px solid #c4c4c4;
  text-align: center;
  padding: 0.25em 0;
  margin-left: 5px;
}

.card-calculator__result {
  margin-top: 1em;
  display: flex;
}

.card-calculator__result-label {
  max-width: 180px;
  margin-right: 24px;
}

.card-calculator__result-value-input {
  border: none;
  font-size: 3.2rem;
  text-align: center;
  width: 70px;
  margin-left: 5px;
}

.card-calculator__submit {
  btn();
  padding: 0.35em 0;
  margin-top: 1.5em;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: $yellow;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    transition: max-width 0.25s ease;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover {
    &:before,
    &:after {
      max-width: 60%;
    }
  }
}

.card-calculator__disclaimer {
  font-size: 1.3rem;
  margin-top: 1.5em;

  &,
  a {
    color: #797979;
  }
}

.discount-popup {
  width: 687px;
  padding: 0;
}

.discount-popup__text {
  font-size: 1.5rem;
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid #c4c4c4;
}

.discount-popup__exclusions {
  font-size: 1.3rem;
}

.discount-popup__exclusions-caption {
  font-weight: bold;
  margin-bottom: 1.5em;
}

.discount-popup__exclusions-list {
  column-count: 4;
  column-gap: 10px;

  @media $small-mobile {
    column-count: 2;
  }

  li {
    list-style: none;
    margin-bottom: 0.5em;
    break-inside: avoid;
  }
}

.quick-view-popup,
.fast-order-popup {
  padding: 30px 40px;
  width: 780px;
  background-color: $night;
  background: url(/i/prod/quick-view-background.png) no-repeat 50%;
  background-size: cover;
  color: $white;

  @media $small-mobile {
    padding: 30px 15px;
  }

  &:before {
    display: none;
  }

  .popup__close {
    top: 25px; right: 25px;
  }

  .popup__close:before,
  .popup__close:after {
    background-color: $white;
  }

  .quick-view__fast-order {
    color: $turq;
  }
}

.fast-order-popup .checkbox::before {
  background-color: $night;
  border-radius: 5px;
  border-color: $turq;
}

.card-calculator-z {
  width: 680px;
  background: var(--Soft-Night, #303030);
  box-shadow: 0 8px 30px 0 rgba(255, 255, 255, 0.05);
  padding: 40px;
  position: absolute;
  z-index: 9999;
  max-width: 100%;
  box-sizing: border-box;
}

.card-calculator__caption-z {
  color: var(--Clear-White, #FFF);
  font-family: Manrope;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0 50px 40px 0;
}

.card-calculator__close-z {
  right: 36px !important;
  top: 36px !important;
}

.card-calculator__close-z.popup__close::before,
.card-calculator__close-z.popup__close::after {
  background: #fff;
}

.card-calculator__close-z.popup__close::before {
  width: 24px;
  height: 1px;
}

.card-calculator__close-z.popup__close::after {
  width: 1px;
  height: 24px;
}

.card-calculator__sizes-z {
  display: none;
}

.card-calculator__tabs-z {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
}

.card-calculator__tabs-button-z {
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background: none;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  padding: 0.5em 1.5em;
  position: relative;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
}

.card-calculator__tabs-button-z.is-active {
  border-bottom-color: #22c1ae;
  -webkit-text-stroke: 0.5px #fff;
}

.card-calculator__line-z {
  display: flex;
  color: var(--Clear-White, #FFF);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  max-width: 100%;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-calculator__input-z {
  color: #fff;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  height: 28px;
  padding: 3px 0 3px 3vw;
  background: transparent;
  width: 4vw;
  text-align: left;
}

.card-calculator__result-z {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: end;
  margin-bottom: 40px;
}

.card-calculator__result-label-z {
  color: var(--Clear-White, #FFF);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.card-calculator__result-value-input-z {
  color: var(--Fresh-Mint, #22C1AE);
  font-family: Manrope;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  background: transparent;
  width: 7.3vw;
  text-align: center;
}

.card-calculator__footer-z {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 20px;
}

.card-calculator__submit-z {
  display: flex;
  width: 294px;
  justify-content: center;
  align-items: center;
  height: 56px;
  border: 1px solid var(--Clear-White, #FFF);
  color: var(--Clear-White, var(--Clear-White, #FFF));
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background: transparent;
  max-width: 100%;
  transition: 200ms linear;
}

.card-calculator__submit-z:hover {
  color: var(--Fresh-Mint, #22C1AE);
}

.card-calculator__disclaimer-z {
  color: rgba(255, 255, 255, 0.8);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.mega-call {
  .checkbox::before {
    border: 1px solid #303030;
    border-radius: 0;
    opacity: 0.5;
  }

  .checkbox::after {
    background: url('/i/hd/check_go.svg') no-repeat 50% / cover;
    border-radius: 0;
    width: 26px; height: @width;
    left: 0;
    top: 0;
  }
}

@media (max-width: 1024px) {
  .card-calculator__caption-z {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .card-calculator__tabs-button-z {
    font-size: 16px;
    padding: 0.5em 0;
  }

  .card-calculator__tabs-z {
    margin-bottom: 20px;
  }

  .card-calculator__line-z {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .card-calculator__input-z {
    min-width: 50px;
  }

  .card-calculator__result-z {
    margin: 30px 0;
    gap: 30px;
  }

  .card-calculator__result-label-z {
    font-size: 16px;
  }
}

.cart-sharing-popup {
  &::before {
    content: none;
  }

  .popup__close::before,
  .popup__close::after {
    background: #ef466f;
  }

  .popup__close::before {
    height: 1px;
  }

  .popup__close::after {
    width: 1px;
  }
}
