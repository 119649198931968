@font-face {
  font-family: 'Manrope';
  src: local('Manrope Light'), local('Manrope-Light'),
    url('/i/fonts/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope Regular'), local('Manrope-Regular'),
    url('/i/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope Medium'), local('Manrope-Medium'),
    url('/i/fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope SemiBold'), local('Manrope-SemiBold'),
    url('/i/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope Bold'), local('Manrope-Bold'),
    url('/i/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans Light'), local('FiraSans-Light'),
    url('/i/fonts/FiraSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
    url('/i/fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans Medium'), local('FiraSans-Medium'),
    url('/i/fonts/FiraSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
    url('/i/fonts/FiraSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
    url('/i/fonts/FiraSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans Black'), local('FiraSans-Black'),
    url('/i/fonts/FiraSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rouble';
  src: url('/i/fonts/rouble-webfont.woff2') format('woff2'),
    url('/i/fonts/rouble-webfont.woff') format('woff');
}
