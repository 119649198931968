@import './_product-icons';

@critical vitrine-critical.css {
  .vitrine-section {
    padding-top: 85px;
    padding-bottom: 85px;
    position: relative;

    @media $small-mobile {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &:after {
      content: '';
      display: block;
      width: 902px; height: 1px;
      max-width: calc(100% - 40px);
      background: $gray;
      position: absolute;
      bottom: 0; left: 50%;
      transform: translateX(-50%);
    }
  }

  .vitrine-section__caption {
    text-align: center;
    margin-bottom: 0.5em;
  }

  vitrine-more-link();

  .vitrine {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;

    @media $small-notebook-and-less {
      grid-template-columns: repeat(2, 1fr);
    }

    @media $small-handheld {
      grid-column-gap: 20px;
      grid-row-gap: 45px;
    }

    @media $small-mobile {
      grid-template-columns: 1fr;
    }

    .slick-list {
      margin-left: -22px;
    }

    .slick-track {
      display: flex;
      min-width: 100%;
    }

    .slick-slide {
      float: none;
      height: auto;
      display: flex !important;
      margin-left: 22px;
      margin-top: 22px;
      padding-right: 1px;
      transition: all 0.25s ease;

      > div {
        flex: 1 auto;
      }

      .vitrine__product {
        display: flex !important;
        height: 100%;
      }
    }

    /* .slick-slide:not(.slick-active) {
      opacity: 0;
    } */

    @media $small-mobile {
      &.vitrine--mobile-scroller {
        display: flex;
        grid-column-gap: 0;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin-left: - $mobile-paddings;
        margin-right: - $mobile-paddings;
        padding-left: $mobile-paddings;
        padding-right: $mobile-paddings;
        scroll-padding: 0 $mobile-paddings;

        &::-webkit-scrollbar {
          display: none;
        }

        .vitrine__product {
          // flex-basis: 70vw;
          flex-basis: 'calc(100vw - %s)' % ($mobile-paddings * 2);
          flex-shrink: 0;
          scroll-snap-align: start;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        .vitrine__product-gallery {
          pointer-events: none;
        }

        .vitrine__product-footer {
          flex-wrap: wrap;
        }

        .vitrine__product-buy-button {
          margin: 0.5em auto 0;
        }
      }
    }
  }

  .showcase-vitrine {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 70px;
    grid-column-gap: 30px;

    @media $small-notebook-and-less {
      grid-template-columns: repeat(3, 1fr);
    }

    @media $small-handheld {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .vitrine--slider {
    overflow: hidden;
    display: flex;

    .vitrine__product {
      flex-basis: 25%;
      padding: 20px;
    }

    .vitrine__product-description {
      font-size: 1.3rem;
    }

    .vitrine__product-price {
      font-size: 1.7rem;
    }

    .vitrine__product-favorite,
    .vitrine__product-compare {
      width: 30px; height: 30px;
      margin-right: 10px;
    }

    .vitrine__product-favorite {
      background-size: 21px auto;
    }

    .vitrine__product-compare {
      background-size: 12px auto;
    }

    .vitrine__product-buy-button {
      width: 80px;
      line-height: 42px;
      font-size: 1.2rem;
    }

    .slick-dots {
      display: flex;

      li + li {
        margin-left: 9px;
      }

      button {
        width: 73px; height: 3px;
        border-radius: 0;
        background: #c4c4c4;
        vertical-align: middle;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 100%; height: 30px;
          position: absolute;
          left: 0; top: 50%;
          transform: translateY(-50%);
        }
      }

      .slick-active button {
        background: $black;
      }
    }
  }

  @media $small-notebook-and-less {
    @media $not-small-mobile {
      .vitrine--one-row {
        .vitrine__product:nth-child(2) ~ .vitrine__product {
          display: none;
        }
      }
    }
  }

  .vitrine--catalogue {
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (min-width: 2200px) {
      width: calc(1920px - 375px - 2vw - 50px);
    }

    @media $small-notebook-and-less {
      grid-template-columns: repeat(2, 1fr);
    }

    @media $handheld {
      @media $not-small-mobile {
        margin-left: -30px;
        margin-right: -30px;
      }
    }

    @media $mobile {
      grid-template-columns: 1fr;
      justify-content: center;
    }

    /* @media $handheld {
      grid-template-columns: repeat(2, 1fr);
    } */

    @media $small-mobile {
      grid-template-columns: 1fr;
    }
  }

  .vintrine--collection {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0;

    @media $notebook-and-less {
      grid-template-columns: repeat(3, 1fr);
    }

    @media $handheld {
      grid-template-columns: repeat(2, 1fr);
    }

    @media $small-handheld {
      grid-row-gap: 30px;
    }

    @media $small-mobile {
      grid-template-columns: 1fr;
    }

    .vitrine__product-name {
      font-size: 1.3rem;
    }

    .vitrine__product-description {
      font-size: 1.3rem;
    }

    .vitrine__product-price {
      font-size: 1.7rem;
      margin-right: 20px;

      @media $small-notebook-and-less {
        font-size: 2rem;
      }
    }

    .vitrine__product-price--old {
      &:after {
        height: 1px;
      }
    }

    .vitrine__product-favorite,
    .vitrine__product-compare {
      width: 30px; height: 30px;
      margin-right: 10px;
    }

    .vitrine__product-favorite {
      background-size: 21px auto;
    }

    .vitrine__product-compare {
      background-size: 12px auto;
    }

    .vitrine__product-buy-button {
      width: 80px;
      line-height: 42px;
      font-size: 1.2rem;
    }
  }

  .vitrine__product {
    min-width: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
  }

  .vitrine--set {
    .vitrine__product {
      margin-top: 80px;
    }
  }

  .vitrine__product-remove {
    btn();
    width: 40px; height: 40px;
    position: absolute;
    left: 50%; bottom: 100%;
    transform: translateX(-50%) rotate(45deg);
    margin-bottom: 35px;

    @media $handheld {
      margin-bottom: 25px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.25s ease;
    }

    &:before {
      width: 40px; height: 2px;
    }

    &:after {
      width: 2px; height: 40px;
    }

    &:hover {
      &:before,
      &:after {
        background: $yellow;
      }
    }
  }

  .vitrine__product-image {
    position: relative;

    .product__image {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .vitrine__product-image-toggler {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0; top: 0;

    .item {
      flex: 1;
    }
  }

  .vitrine__product-image-dots {
    display: flex;

    .item {
      flex: 1;
      height: 2px;
      background-color: $white;
      transition: all 250ms ease;
    }

    .item.is-active {
      background-color: $turq;
    }
  }

  .vitrine__product-content {
    margin-top: 15px;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .vitrine__product--archive & {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }

    @media $small-mobile {
      margin-top: 10px;
    }
  }

  .vitrine__product-icons {
    margin-bottom: 15px;
    min-height: 32px;
    flex-wrap: wrap;
  }

  .vitrine__product-name {
    font-size: 1.6rem;
    font-weight: $medium;
    margin-bottom: 0.25em;
    color: $white;
    flex: 1;

    .vitrine__product--archive & {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    @media $small-mobile {
      font-size: 1.3rem;
    }
  }

  .vitrine__product-link {
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .vitrine__product-description {
    line-height: 1.4;
    font-size: 1.6rem;
    color: $white;
    margin-top: 15px;

    .vitrine__product--archive & {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  .vitrine__product-article {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.6rem;
  }

  .vitrine__product-price-wrapper {
    display: flex;
    align-items: center;
  }

  .vitrine__product-price {
    white-space: nowrap;
    font-size: 2.4rem;
    font-weight: $medium;
    margin-right: 10px;
    color: $white;
  }

  .vitrine__product-price--old {
    color: $gray;
    position: relative;
    font-size: 1.5rem;
    display: inline-block;

    &:after {
      content: '';
      display: block;
      width: calc(100% + 4px); height: 1px;
      background: $gray;
      position: absolute;
      left: -2px; top: 50%;
      transform: translateY(-50%);
    }
  }

  .vitrine__product-amount {
    position: absolute;
    left: 0; bottom: -10px;
    color: $white;
    font-size: 1.3rem;
  }

  .vitrine__product-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 40px;

    .vitrine__product--archive & {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      margin-top: 0;
      margin-left: 15px;
    }

    @media $handheld {
      margin-top: 0.75em;
    }
  }

  .vitrine__product-favorite {
    btn();
    width: 32px; height: @width;
    flex-shrink: 0;
    background: url(/i/favorite-icon.svg) no-repeat 50%;
    background-size: contain;
    margin-right: 15px;
    margin-left: auto;
    filter: brightness(0) invert(1);

    @media only screen and (min-width: 2101px) {
      margin-right: 15px;
    }

    @media $small-handheld {
      background-size: 21px auto;
      margin-right: 0;
    }

    &.is-active {
      filter: none;
    }
  }

  .vitrine__product-compare {
    btn();
    width: 32px; height: @width;
    flex-shrink: 0;
    background: url(/i/compare-icon.svg) no-repeat 50% 40%;
    background-size: contain;
    margin-right: 15px;
    filter: brightness(0) invert(1);

    &.is-active {
      filter: none;
    }
  }

  .vitrine__product-buy-button {
    btn();
    width: 128px;
    height: 56px;
    line-height: 56px;
    flex-shrink: 1;
    font-size: 1.6rem;
    font-weight: $bold;
    border: 1px solid $turq;
    color: $turq;

    @media $notebook-and-less {
      font-size: 1.6rem;
    }

    @media $small-handheld {
      font-size: 1.5rem;
      line-height: 37px;
      width: 130px;
      height: 45px;
    }

    &:hover,
    &.is-active {
      background: $turq;
      color: $black;
    }

    .vitrine__product--archive & {
      width: 114px;
    }
  }

  .vitrine__product-controls {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .vitrine__product-color {
    width: 64px; height: @width;
    border-radius: 50%;
    position: absolute;
    left: 30px; top: 30px;
    z-index: 10;

    @media $small-handheld {
      width: 48px; height: @width;
      left: 0; top: 0;
    }

    .quick-view & {
      top: auto;
      bottom: 30px;

      @media $small-handheld {
        left: 0; top: auto;
        bottom: 0;
      }
    }
  }

  .vitrine__link {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
  }

  for $row in 1..4 {
    for $col in 1..3 {
      .vitrine__link--pos-{$row}-{$col} {
        grid-row-start: $row;
        grid-column-start: $col;

        @media $small-notebook-and-less {
          grid-column-start: min($col, 2);
        }

        @media $desktop {
          +media-lt(1170px) {
            grid-column-start: 1;
          }
        }

        @media $small-mobile {
          grid-column-start: 1;
        }
      }
    }
  }
}

.quick-view {
  position: relative;
  font-size: 1.1rem;
}

.quick-view__name,
.fast-order-popup__product-name {
  display: inline-block;
  font-family: $caption-font;
  font-size: 2.4rem;
  margin-bottom: 18px;
  text-decoration: none;
  margin-right: 50px;
  color: $white;
}

.quick-view__article {
  position: absolute;
  right: 100%; top: -15px;
  font-size: 1.2rem;
  line-height: 44px;
  padding: 15px 0;
  background: #b8f4ff;
  transform: rotate(-90deg);
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.quick-view__layout,
.fast-order__layout {
  display: flex;
  column-gap: 30px;

  @media $mobile {
    flex-direction: column;
    row-gap: 20px;
  }
}

.quick-view__content,
.fast-order-popup__content {
  flex: 1 auto;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quick-view__layout-left,
.fast-order__layout-left {
  flex-basis: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media $mobile {
    row-gap: 20px;
  }
}

.quick-view__image-container,
.fast-order-popup__product-image-container {
  img {
    min-height: 215px;

    @media $mobile {
      width: 100%;
    }
  }
}

.quick-view__params {
  column-count: 2;
  column-gap: 10px;
  margin-bottom: 75px;

  a {
    color: $turq;
  }
}

.quick-view__params-entry {
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);

  span {
    @media $mobile {
      text-align: end;
    }
  }
}

.quick-view__commerce-block {
  margin-top: auto;
}

.quick-view__price-line {
  display: flex;
  align-items: flex-start;
  margin-top: auto;

  @media $mobile {
    align-items: flex-start;
    justify-content: space-between;
  }
}

.quick-view__amount-label,
.quick-view__price-block__label,
.fast-order-popup__price-label {
  font-size: 1.4rem;
  margin-bottom: 15px;
  display: inline-block;
}

.fast-order-popup__price-label {
  display: block;
  line-height: normal;
}

.quick-view__amount {
  margin-bottom: 20px;

  @media $mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    flex-basis: auto;
  }

  .spinner {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
  }

  .spinner__input {
    background-color: transparent;
    color: $white;
    width: 92px;
  }

  .spinner__control::before {
    border-color: $white;
    width: 18px;
    height: 18px;
  }

  .card-page__calculator-link {
    font-size: 1.4rem;
    font-weight: $bold;
    border-bottom: 0;
    margin: 0 0 0 32px;
    left: 0;
  }

  .card-page__calculator-link::before {
    background: url(/i/prod/calc.svg) no-repeat 50%;
    width: 32px;
    height: 32px;
  }
}

.quick-view__price-block {
  flex-basis: calc(50% - 10px);
  margin-left: 50px;
  display: flex;
  flex-direction: column;

  @media $mobile {
    flex-basis: auto;
  }
}

.quick-view__old-price {
  white-space: nowrap;
  display: inline-block;
  font-size: 2rem;
  color: #797979;
  position: relative;
  align-self: flex-start;
  order: 1;

  &:before {
    content: '';
    display: block;
    width: calc(100% + 6px); height: 1px;
    background: #797979;
    position: absolute;
    left: -3px; top: 50%;
  }
}

.quick-view__price {
  white-space: nowrap;
  font-size: 4rem;
  font-weight: bold;
  line-height: 46px;

  @media $small-mobile {
    font-size: 3rem;
  }
}

.quick-view__old-price + .quick-view__price {
  margin-top: 0;
}

.quick-view__buy-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media $mobile {
    justify-content: center;
  }
}

.quick-view__buy-button {
  btn();
  width: 260px;
  line-height: 72px;
  font-size: 1.8rem;
  background: $turq;
  white-space: nowrap;
  margin-top: 0 !important;
  color: $asphalt;
  font-weight: $weight-black;
}

.quick-view__content-buttons,
.fast-order-popup__content-buttons {
  display: flex;
  column-gap: 40px;
  margin-bottom: 25px;

  a {
    position: relative;
  }

  a + a::before {
    content: '';
    width: 1px;
    height: 32px;
    background-color: #646464;
    left: -60%;
    display: block;
    position: absolute;
  }
}

.quick-view__compare-button {
  btn();
  width: 32px; height: @width;
  background: url(/i/compare-vitrine-icon.svg) no-repeat 50%;
  filter: invert(1);

  &.is-active {
    background-image: url(/i/compare-vitrine-icon-active.svg);
  }
}

.quick-view__favorite-button {
  btn();
  width: 34px; height: 32px;
  background: url(/i/favorite-vitrine-icon.svg) no-repeat 50%;
  filter: invert(1);

  &.is-active {
    background-image: url(/i/favorite-vitrine-icon-active.svg);
  }
}

.quick-view__buy-button-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quick-view__fast-order {
  text-decoration: none;
  font-weight: $bold;
  margin-top: 12px;
  font-size: 1.8rem;
}

.product-price-caption {
  font-size: 22px;
  font-weight: bold;

  @media $small-handheld {
    font-size: 18px;
  }
}
