.scroller-dots {
  margin-top: 1.5em;
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 430px;

  li {
    list-style: none;
    width: 100%;
    display: block;
    cursor: pointer;
  }

  button {
    btn();
    width: 8px; height: 2px;
    font-size: 0;
    background: transparent;
    border-bottom: 1px solid #b3b5b0;
    transition: all 250ms ease;
    width: 100%;
  }

  .slick-active button {
    background: $turq;
    border-color: $turq;
  }
}

.slick-arrow {
  btn();
  width: 40px; height: 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  font-size: 0;
  filter: brightness(0) invert(1);
  opacity: 0.3;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.slick-prev {
  background: url('/i/arrow-left.svg') 50% / contain no-repeat;
}

.slick-next {
  background: url('/i/arrow-right.svg') 50% / contain no-repeat;
}

.scroller-dots {
  margin-top: 1.5em;
}

.alert {
  z-index: 9999;
  position: fixed;
  left: 0;
  bottom: 100px;
  max-width: 40vw;
  background-color: #d6eaee;
  padding: 20px 150px 20px 11vw;
  transition: all 150ms ease;
  transform: translateX(-100%);

  @media $small-handheld {
    bottom: 45px;
    max-width: 100vw;
    padding-left: 20px;
    padding-right: 100px;
  }

  &.--shown {
    transform: translateX(0);
  }
}

.alert-content {
  font-weight: 400;
  font-size: 16px;

  @media $small-mobile {
    font-size: 12px;
  }

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;

    @media $small-mobile {
      font-size: 12px;
    }

    &::after {
      position: absolute;
      content: '';
      border-bottom: 1px solid $black;
      bottom: -2px;
      left: 0;
      width: 100%;
    }
  }
}

.alert-btn {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  right: 35px; top: 50%;
  transform: translateY(-50%);
  padding: 15px 25px;
  background-color: transparent;
  border: 2px solid $white;
  color: $black;
  transition: all 150ms ease;
  text-decoration: none;

  @media $small-mobile {
    right: 20px;
  }

  &:hover {
    background-color: $white;
  }
}
