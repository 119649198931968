.ui-autocomplete {
  z-index: 1010;
  // max-height: 431px;
  overflow: auto;
  background: $white;
  list-style: none;
  word-wrap: break-word;
  border: 2px solid $blue;
  border-top-width: 1px;
  box-sizing: border-box;

  .ps-scrollbar-y-rail {
    z-index: 100;
  }

  @media $landscape-mobile {
    left: auto !important;
    right: 20px;
    width: 300px !important;
  }
}

.ui-menu {
  box-shadow: 0px 2px 10px -6px;
  border: 1px solid #959595;
  border-top: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .ui-menu-item {
    padding: 0;
    padding: 15px 20px;

    &:first-child {
      font-weight: 400;
      font-size: 14px;
      color: $black;
      position: relative;
      display: inline-block;
      padding: 0;
      margin: 10px 20px;
    }

    & > .linck {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $yellow;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }

    > a {
      display: block;
      color: $black;
    }

    &:hover {
      z-index: 10;
    }

    + .ui-menu-item:not(:nth-child(2)) {
      // margin-top: -1px;
      border-top: 1px solid #d3d1d1;
    }

    .ui-menu-item-wrapper {
      font-size: 14px;
      background-color: $white;
      transition: all 100ms ease;
      color: #303030;
      padding: 0;

      &.ui-state-active {
        border: 0 !important;
        margin: 0 !important;
      }
    }
  }
}



.autocomplete-item {
  display: flex;
  align-items: center;
  padding: 5px;
  background: white;
  gap: 10px;

  &:hover {
    background: $light-gray;
    z-index: 10;
  }

  span {
    display: inline-block;
    max-width: 100%;
  }
}

.autocomplete-item--article {
  display: inline;
}

.autocomplete-item--pic {
  width: 30px;
  height: 30px;
  text-align: center;
  flex-shrink: 0;
  display: block;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.autocomplete-item--content {
  display: block;
  font-size: 12px;
  line-height: 120%;

  .autocomplete-pic + & {
    margin-left: 85px;
  }
}

.autocomplete-item--price {
  padding-top: 10px;
  display: none;
}


// Selectric

@critical {
  .select-container {
    display: inline-block;
  }

  select {
    border: none;
    border-top: 1px solid #c4c4c4;
    border-bottom: @border-top;
    color: #000;
    height: 53px;
    line-height: @height - 3px;
    text-align: left;
    font-size: 1.5rem;
    padding-right: 50px;
    background: $white url(/i/select-arrow.svg) no-repeat calc(100% - 10px) 50%;

    @media $handheld {
      font-size: 1.3rem;
      height: 31px;
      line-height: @height - 3px;
      padding-right: 35px;
      background-size: 12px auto;
      background-position: calc(100% - 8px);
    }
  }
}

.selectric {
  border: none;
  border-top: 1px solid #c4c4c4;
  border-bottom: @border-top;
  background: $white;
  color: #000;
  height: 53px;
  line-height: @height - 3px;
  text-align: left;

  @media $handheld {
    height: 31px;
    line-height: @height - 3px;
  }

  .selectric-open & {
    border-color: $yellow;
  }

  .label {
    line-height: 53px;
    font-size: 1.5rem;
    margin-left: 0;
    margin-right: 50px;

    @media $handheld {
      font-size: 1.3rem;
      line-height: 31px;
      margin-right: 35px;
    }
  }

  .button {
    width: 50px; height: 100%;

    @media $handheld {
      width: 35px;
    }

    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 11px; height: @width;
      border-left: 2px solid $black;
      border-bottom: 2px solid $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: rotate(-45deg) translate(50%, -50%);

      @media $handheld {
        width: 8px; height: @width;
      }
    }
  }
}

.selectric-items {
  text-align: left;

  li {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1.5rem;

    @media $handheld {
      font-size: 1.3rem;
    }
  }

  .disabled {
    display: none;
  }
}


// alertify

.alertify {
  border: 2px solid $black;
  width: 250px;
  margin-left: 0;
  top: 50%; left: 50% !important;
  transform: translate(-50%, -50%);
  background: $white;
}

.alertify-message {
  margin-bottom: 15px;
}

.alertify-button {
  display: inline-block;
  margin-left: 0 !important;
  line-height: 30px !important;
  width: 40px;
}

.alertify-button-ok {
  background: $black;
  color: $white;
  box-shadow: 0 0 0 2px $black;

  &:hover {
    background: $white;
    color: $black;
  }
}

.alertify-button-cancel {
  background: $white;
  box-shadow: 0 0 0 2px $black;
  margin-left: 10px !important;

  &:hover {
    background: $black;
    color: $white;
  }
}
