.footer {
  background-color: $asphalt;
}

.footer-top {
  display: grid;
  grid-template-columns: 1fr 1fr 17% 17% 17%;

  @media $handheld {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__column {
    padding: 65px 0 80px;

    + .footer__column {
      padding-left: 30px;
      padding-right: 30px;

      @media $desktop {
        border-left: 1px solid rgba($white, 10%);
      }
    }
  }
}

.footer__column {
  box-sizing: border-box;

  @media $handheld {
    &.footer__column--menu {
      border-top: 1px solid rgba($white, 10%);
    }
  }
}

.footer__contacts {
  @media $handheld {
    grid-column: 2 / 4;
  }
}

.footer__logo {
  font-size: 0;
  display: block;
  width: width('i/footer-logo.svg');
  height: height('i/footer-logo.svg');
  background: url('/i/footer-logo.svg') 50% no-repeat;
  margin-bottom: 30px;
}

.footer-bottom {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 17% 17% 17%;
  border-top: 1px solid rgba($white, 10%);
  padding: 45px 0 30px;

  @media $handheld {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer__address {
  margin-bottom: 40px;
  font-size: 1.6rem;
}

.footer__worktime {
  font-size: 2rem;
  font-weight: $medium;
  margin-bottom: 40px;
  color: $white;

  @media $small-notebook-and-less {
    font-size: 1.6rem;
  }
}

.footer__phones-list {
  padding-left: 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 32px; height: @width;
    background: url('/i/callback-icon.svg') 50% no-repeat;
    filter: brightness(0) invert(1);

    @media only screen and (max-width: 1200px) {
      content: none;
    }
  }
}

.footer__phone-link {
  color: $white;
  font-size: 2rem;
  font-weight: $medium;
  line-height: 2.4rem;

  @media $small-notebook-and-less {
    font-size: 1.6rem;
  }
}

.footer__emails-list {
  margin-bottom: 50px;
}

.footer__messengers {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer__messengers-item {
  margin-left: 0;
}

.footer__menu ul {
  list-style: none;
}

.footer__menu-item {
  display: block;
  margin-bottom: 35px;

  @media $small-notebook-and-less {
    margin-bottom: 25px;
  }

  &.footer__menu-item-head a {
    color: $white;
    font-size: 2.2rem;

    @media $small-notebook-and-less {
      font-size: 1.8rem;
    }
  }
}

.footer__menu-link {
  display: inline-block;
  color: rgba($white, 55%);
  font-size: 1.8rem;
  font-weight: 300;
  text-decoration: none;

  @media $small-notebook-and-less {
    font-size: 1.6rem;
  }

  @media $handheld {
    font-size: 1.4rem;
  }

  &:hover {
    color: $white;
  }
}

.footer__column--copyright {
  color: $text-gray;
  font-size: 1.6rem;

  @media $not-small-notebook {
    display: flex;
    flex-direction: row;
  }

  @media $handheld {
    font-size: 1.4rem;
  }
}

.footer__column--dev-copyright {
  grid-column: 3 / 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;

  @media $handheld {
    flex-direction: column;
    gap: 10px;
  }
}

.footer__column--dev-copyright-links {
  display: block;

  @media $handheld {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 1.3rem;
    color: $text-gray;

    &:hover {
      color: $white;
    }
  }
}

.footer__links-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.footer__link {
  color: $white;
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;

  @media $handheld {
    font-size: 1.4rem;
  }
}