@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  .text-container {
    a,
    a:visited {
      text-decoration: underline !important;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    a[href^="javascript:"]:after,
    a[href^="#"]:after {
      content: "";
    }
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  select {
    background: #fff !important;
  }

  .wrapper,
  .wide-wrapper,
  .small-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .no-print,
  aside,
  .header__bottom,
  .header__worktime,
  .header__callback,
  .header__logo,
  .header__search,
  .header__icons-block,
  .header__burger,
  .header__mobile-phone-button,
  .footer,
  .menu,
  .breadcrumbs,
  .pager,
  .vitrine__product-icons,
  .vitrine__product-thumbs,
  .vitrine__product-dots,
  .vitrine__product-remove,
  .vitrine-more-link,
  .go-top-btn,
  .only-small-mobile {
    display: none !important;
  }

  .site-container {
    display: block;
  }

  .header {
    position: relative;
  }

  .header__wrapper {
    display: block;
  }

  .header__top {
    padding-bottom: 0;
  }

  .header__phones-list {
    margin-left: 0 !important;
  }

  .vitrine__product {
    padding: 0 5px;
  }

  .vitrine__product-price--old {
    text-decoration: line-through;

    &:after {
      display: none;
    }
  }

  .vitrine.vitrine--set {
    display: block !important;
    font-size: 0 !important;
  }

  .seen-panel {
    display: none !important;
  }

  .vitrine--set .vitrine__product {
    margin-top: 0;
    break-inside: avoid !important;
    display: inline-block !important;
    max-width: 33.3% !important;
    margin-top: 50px !important;
  }

  .vitrine__product-gallery-item + .vitrine__product-gallery-item {
    display: none;
  }
}
